<template>
  <div class="dispute-details">
    <div class="dispute-header">
      <p class="reference">
        {{ $t('product_page.orders.package_reference') }}
        {{ order.ups_tracking }}
      </p>

      <div
        class="reasons"
        v-for="(reason, i) in getLists.dispute_reasons"
        :key="reason.value"
      >
        <input
          type="checkbox"
          :id="'reason_' + reason.value"
          :value="reason.value"
          v-model="declineReasons[i]"
        />
        <label :for="'reason_' + reason.value">{{ reason.label }}</label>
      </div>
    </div>

    <div class="dispute-details-footer">
      <el-input
        v-model="description"
        :autosize="{ minRows: 4, maxRows: 4 }"
        type="textarea"
        input-style="color: white;"
        :placeholder="$t('product_page.orders.dispute_description')"
      />
    </div>

    <div class="send-dispute">
      <button class="action-light" @click="sendReport()">{{ $t('commons.confirm') }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ElMessage } from 'element-plus'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      declineReasons: [],
      description: null,
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists',
    }),
  },
  methods: {
    ...mapActions({
      getAllTransactions: 'getAllTransactions',
      disputeDelivery: 'disputeDelivery',
    }),
    async sendReport() {
      const payload = {
        order: {
          order_uuid: this.order.order_uuid,
        },
        reasons: [],
        description: this.description,
      }

      for (const key in this.declineReasons) {
        if (this.declineReasons[key] === true) {
          payload.reasons.push(Number([key][0]))
        }
      }

      if (payload.reasons.length < 1) {
        ElMessage({
          message: this.$t('commons.inputs_missing'),
          type: 'error',
          grouping: true,
        })
      } else {
        await this.disputeDelivery(payload)
          .then(() => {
            ElMessage({
              message: this.$t('product_page.orders.success_create_dispute'),
              type: 'success',
              grouping: true,
            })
          })
          .finally(() => {
            this.getAllTransactions()
            this.$emit('close')
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.dispute-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.dispute-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 20px 0;
  margin-bottom: 10px;

  p {
    margin-bottom: 15px;
  }
}

.reasons {
  margin-bottom: 8px;
  color: var(--subtitle-text-color);
  font-weight: 300;
  input {
    margin-right: 10px;
  }

  input,
  label {
    cursor: pointer;
    color: var(--text-color);
  }
}

.dispute-details-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--background-color-3);
  color: var(--text-color);
}

.informations {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.title {
  font-size: 12px;
  margin-bottom: 10px;
}

.reference {
  font-size: 16px;
  color: var(--text-color);
  width: 100%;
  border-bottom: 1px solid var(--border-6);
  padding-bottom: 10px;
}

.send-dispute {
  margin: 10px 0;

  button {
    background-color: $primary-color;
    color: var(--text-color);
    margin: 22px;
    margin-bottom: 0px;
    border-radius: 40px;
    padding: 7px 16px;
    width: 200px;
    border: 0;
    font-size: 15px;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .dispute-details {
    padding: 0;

    .dispute-header {
      margin-top: 5px;
    }
  }
}
</style>
